import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

const languages = [
  {id: 'nb', title: 'Norsk', isDefault: true},
  {id: 'en', title: 'English'}
];

const {Provider, Consumer} = React.createContext();

const LanguageContextProvider = ({children}) => {
  const lang = languages.sort((a, b) => (a.isDefault ? -1 : 1)).map(lang => lang.id);
  const [selectedLanguage, setSelectedLanguage] = useState(lang[0]);
  const [langList, setLangList] = useState(lang);

  // Get selected language, uses fallback languages if available
  const localize = text => {
    if (!text) return '';
    for (let l of langList) {
      if (text[l]) return text[l];
    }
    return ''; // If no languages are available
  };

  // Function to change language, used in languageSwitch
  const changeLanguage = newLang => {
    setSelectedLanguage(newLang);
    setLangList(langList.sort((a, b) => (a === newLang ? -1 : 1)));
    localStorage.setItem('lang', newLang);
  };

  // Update language on first load
  useEffect(() => {
    const localLang = localStorage.getItem('lang');
    if (localLang) {
      changeLanguage(localLang);
    } else {
      localStorage.setItem('lang', selectedLanguage);
    }
  }, []);

  // Return the actual context provider
  return (
    <Provider
      value={{
        selectedLanguage,
        langList,
        changeLanguage,
        localize
      }}
    >
      {children}
    </Provider>
  );
};

LanguageContextProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export {LanguageContextProvider, Consumer as LanguageContextConsumer};
