import React from "react";
import PropTypes from "prop-types";
import styles from "./NavItem.module.scss";
import { Link } from "react-router-dom";

const NavItem = ({ name, color, index }) => {
  return (
    <li
      className={styles.item}
      style={{ backgroundColor: color.includes("#") ? color : `var(${color})` }}
    >
      <a
        href={`#${name.en.toLowerCase()}`}
        data-desktop={name.en}
        data-mobile={`0${index + 1}.`}
        aria-label={name.en}
      />
    </li>
  );
};

export default NavItem;
