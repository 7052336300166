import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import styles from "./Layout.module.scss";
import { LanguageContextProvider } from "../../contexts/languageContext";

import "./Layout.module.scss";
import Nav from "../Nav/Nav";

const Layout = ({ children }) => {
  return (
    <LanguageContextProvider>
      <div>
        <main className={styles.contentWrapper}>{children}</main>
      </div>
    </LanguageContextProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
