import React from "react";
import NavItem from "../NavItem/NavItem";
import styles from "./Nav.module.scss";
import logo from "../../staticfiles/logo/Black.svg";
import { navigate } from "gatsby";

const Nav = ({ categories, setSelectedSection }) => {
  return (
    <nav className={styles.nav}>
      <div className={styles.banner}>
        <a
          className={styles.cityGuideStamp}
          onClick={() => {
            navigate("/");
            setSelectedSection(null);
          }}
        >
          <span>Oslobukta</span>
          <br />
          City Guide
        </a>
        <a href="https://oslobukta.no" className={styles.logo}>
          <img src={logo} />
        </a>
      </div>
      <ul className={styles.navItems}>
        {categories.map(({ name, color }, index) => (
          <NavItem
            name={name}
            color={color}
            key={`nav-item-${index}`}
            index={index}
          />
        ))}
      </ul>
    </nav>
  );
};

export default Nav;
